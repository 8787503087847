<template>
  <div style="display: flex;background-color: #FFFFFF;
  border: 1px solid #dee2e6;justify-content: space-between;border-radius: 3px;align-items: center">
    <Breadcrumb style="border: none" :model="items"/>
    <Button style="height: 33px" class="p-button-text p-mr-2" @click="$router.go(-1)">返回</Button>
  </div>
  <div class="page p-mt-3" style="border-radius: 3px">
    <div style="text-align: center" class="p-mt-4 p-col-12">
      <label style="font-size: 20px; font-weight: bold">{{ data.title }}</label>
    </div>

    <div class="p-p-2" style="text-align:center;font-size: 14px;color: #999999">
      <label class="p-mr-4">发布行业：{{ data.industry }}</label>
      <label class="p-mr-2">发布时间：{{ new Date(data.time).toLocaleString() }}</label>
    </div>
    <div class="p-p-12 contents" v-html="data.content">
    </div>

  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      data: {},
      items: [
        {label: '消息推送', to: '/MessageList'},
        {label: '消息发送列表', to: '/MessageList'},
        {label: '消息详情', to: '/NewsDetails'}
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let id = this.$route.query.id
      this.$http.get('/send/getOneMessage?id=' + id).then((res) => {
        this.data = res.data
        console.log(res.data)
      })
    }
  }
}
</script>

<style scoped>
.contents {
  padding: 20px;
}

.contents p {
  margin: auto 20px;
  display: inline-block;
  padding: auto 20px;
}
</style>